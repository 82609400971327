<template>
    <div>
        <div class="d-flex justify-space-between">
            <v-tabs v-model="dashboardTab">
                <v-tab v-if="hasSupportAccess" value="performance">Performance</v-tab>
                <v-tab v-if="hasSupportAccess" value="smart">Smart</v-tab>
                <v-tab value="warehouse">Warehouse</v-tab>
                <v-tab value="pretreatment">Pretreatment</v-tab>
                <v-tab v-if="hasSupportAccess" value="invoice">Invoice</v-tab>
                <v-tab v-if="hasSupportAccess" value="rework">Rework</v-tab>
            </v-tabs>
        </div>
        <v-window v-model="dashboardTab" disabled>
            <v-window-item v-if="hasSupportAccess" value="performance" :transition="false" :reverse-transition="false">
                <performance-dashboard></performance-dashboard>
            </v-window-item>
            <v-window-item v-if="hasSupportAccess" value="smart" :transition="false" :reverse-transition="false">
                <smart-dashboard></smart-dashboard>
            </v-window-item>
            <v-window-item value="warehouse" :transition="false" :reverse-transition="false">
                <warehouse-dashboard></warehouse-dashboard>
            </v-window-item>
            <v-window-item value="pretreatment" :transition="false" :reverse-transition="false">
                <pretreatment-table></pretreatment-table>
            </v-window-item>
            <v-window-item v-if="hasSupportAccess" value="invoice" :transition="false" :reverse-transition="false">
                <invoice-dashboard></invoice-dashboard>
            </v-window-item>
            <v-window-item v-if="hasSupportAccess" value="rework" :transition="false" :reverse-transition="false">
                <rework-dashboard></rework-dashboard>
            </v-window-item>
        </v-window>
        <smart-pretreated-dialog></smart-pretreated-dialog>
    </div>
</template>
<style scoped>
    .v-window-item {
        width: 100%;
        min-height: 100vh;
    }
</style>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import _cloneDeep from "lodash/cloneDeep";
    import tasks from "../constants/tasks";
    function initialState() {
        return {
            saving: false,
            loading: true,
        };
    }
    export default {
        name: "DashboardView",
        data: function () {
            return initialState();
        },
        mounted: function () {
            
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            }
        },
        computed: {
            ...mapGetters(['hasSupportAccess']),
            dashboardTab: {
                get() {
                    return this.$store.state.auth.dashboardTab
                },
                set(value) {
                    this.$store.commit('setDashboardTab', value)
                }
            },
            tasks() {
                return _cloneDeep(tasks.types).filter(item => item.schedule).sort((a, b) => a.scheduleOrder - b.scheduleOrder);
            },
        }
    };
</script>