<template>
    <div v-if="column" :key="column.name" class="smart-column" :class="{'expand-column' : column.expand, 'shrink-column' : !column.expand }">
        <div class="column-header">
            {{ column.name }}  <v-badge :color="column.jobs.length > 0 ? 'info' : 'secondary'" :content="column.jobs.length" inline></v-badge>
            <v-tooltip :text="column.expand ? 'Hide Column' :'Show Column'">
                <template v-slot:activator="{ props }">
                    <v-btn v-bind="props"
                           class="expand-btn"
                           variant="plain"
                           @click="expandSmartColumn(column)"
                           :icon="column.expand ? 'mdi-arrow-expand-up' : 'mdi-arrow-expand-left'"
                           size="x-small"></v-btn>
                </template>
            </v-tooltip>
            <v-tooltip text="Bulk Assign" v-if="column.status == $constants.jobs.JOBSTAT_SCHEDULE && column.jobs.length > 0 && column.expand">
                <template v-slot:activator="{ props }">
                    <v-btn v-bind="props"
                           color="primary"
                           @click="showBulkSchedule"
                           icon="mdi-format-list-text"
                           class="float-right"
                           size="x-small"></v-btn>
                </template>
            </v-tooltip>
        </div>
        <div :class="{'d-none':!column.expand, 'd-inline-block':column.expand}" class="w-100 h-100">
            <div class="totals-block">
                <div class="d-flex justify-space-between">
                    <span class="total-label">TOTAL</span>
                    <span>${{ numberWithCommas(columnTotal(column), 2) }}</span>
                </div>
                <div class="d-flex justify-space-between scheduled-block">
                    <span class="total-label"><span v-if="column.status >= $constants.jobs.JOBSTAT_LINE1">SCHEDULED</span>&nbsp;</span>
                    <span v-if="column.scheduledTotal">${{ numberWithCommas(column.scheduledTotal, 2) }}</span>
                </div>
            </div>
            <div v-if="column.status >= $constants.jobs.JOBSTAT_LINE1 && showColours" class="colour-block">
                <div class="text-left">
                    <span class="total-label">COLOURS</span>
                    <table class="w-100">
                        <tr v-for="colour in columnColours(column)" :key="colour">
                            <td>{{ colour }}</td>
                            <td class="text-right">{{ columnColourTotal(colour, column) }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <draggable class="card-list"
                       :list="column.jobs"
                       group="jobs"
                       draggable=".moveable-card"
                       @change="updateCard(column, $event)"
                       v-bind="dragOptions"
                       itemKey="id">
                <template #item="{ element }">
                    <smart-card :job="element" :search-term="searchTerm"></smart-card>
                </template>
            </draggable>
        </div>
    </div>
</template>
<style scoped lang="scss">
    @import '../../../styles/smart.scss'
</style>
<script>
    import { mapActions, mapState } from "vuex";
    import { numberWithCommas } from "../../../mixins/numberMixins";
    import draggable from 'vuedraggable'
    import Cookies from 'js-cookie';
    function initialState() {
        return {
            dragOptions: {
                animation: 200,
                group: "jobs",
                disabled: false,
                ghostClass: "smart-ghost",
            }
        };
    }
    export default {
        name: "SmartDashboard",
        components: {
            draggable,
        },
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        onMounted: function () {
        },
        props: {
            column: Object,
            showColours: Boolean,
            searchTerm: String
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
                "refreshSmartData",
                "updateSmartJobStatus",
                "expandSmartColumn",
                "saveSmartDates",
                "showBulkSchedule",
            ]),
            numberWithCommas,
            columnTotal(column) {
                if (column.jobs.length > 0){
                    return column.jobs.reduce((acc, obj) => acc + obj.taskSubTotal, 0);
                }
                return 0;
            },
            columnColourTotal(colour, column) {
                return column.jobs.filter(job => job.colours.replace('Duralloy ', '') === colour).length;
            },
            columnColours(column){
                if (column.jobs.length > 0){
                    return [...new Set(column.jobs
                      .filter(item => item?.colours) // Filter out null, undefined, and empty strings
                      .map(item => item.colours.replace('Duralloy ', ''))     // Extract the colours property
                    )].sort();
                }
                return '';
            },
            updateCard(column, event) {
                let newIndex = null;
                let job = null;
                if (event.moved && event.moved.element) {
                    newIndex = event.moved.newIndex;
                    job = event.moved.element;
                }
                if (event.added && event.added.element) {
                    newIndex = event.added.newIndex;
                    job = event.added.element;
                }
                if (newIndex != null && job != null) {
                    this.updateSmartJobStatus({ job: job, status: column.status, taskId: job.jobTaskId, order: newIndex, date: column.statusDate });
                }
            }
        },
        computed: {
            ...mapState({
                user: (state) => state.auth.user,
                loadingData: (state) => state.smart.loadingData,
                smartData: (state) => state.smart.smartData,
            }),
        },
        watch: {
        }
    };
</script>