import { removeNull } from '../mixins/stringMixins';
export function encodeContactBasic(formData, contact, prefix = '') {
    if (contact.id) {
        formData.append(prefix + 'id', contact.id);
    }
    formData.append(prefix + 'name', removeNull(contact.name));
    formData.append(prefix + 'accountsEmail', removeNull(contact.accountsEmail));
    formData.append(prefix + 'phone', removeNull(contact.phone));
    if (contact.defaultNotifierEmail) {
        formData.append(prefix + 'defaultNotifierEmail', removeNull(contact.defaultNotifierEmail.toString()));
    }
    formData.append(prefix + 'importFileImportType', removeNull(contact.importFileImportType));
    if (typeof contact.contactGroup === 'object') {
        formData.append(prefix + 'contactGroup.id', contact.contactGroup.id);
    } else if (typeof contact.contactGroup === 'number') {
        formData.append(prefix + 'contactGroup.id', contact.contactGroup);
    }
    return formData;
}

export function encodeProductBasic(formData, product, prefix = '') {
    if (product.id) {
        formData.append(prefix + 'id', product.id);
    }
    formData.append(prefix + 'type', product.type);
    formData.append(prefix + 'name', removeNull(product.name));
    formData.append(prefix + 'code', removeNull(product.code));
    formData.append(prefix + 'fixedSize', product.fixedSize);
    formData.append(prefix + 'uomType', product.uomType);
    formData.append(prefix + 'complexity', product.complexity);
    return formData;
}

export function encodeColourBasic(formData, colour, prefix = '') {
    if (colour.id) {
        formData.append(prefix + 'id', colour.id);
    }
    formData.append(prefix + 'name', removeNull(colour.name));
    formData.append(prefix + 'code', removeNull(colour.code));
    formData.append(prefix + 'brand', removeNull(colour.brand));
    formData.append(prefix + 'range', removeNull(colour.range));
    formData.append(prefix + 'finishType', removeNull(colour.finishType));
    if (colour.red != null && colour.green != null && colour.blue != null) {
        formData.append(prefix + 'red', colour.red);
        formData.append(prefix + 'green', colour.green);
        formData.append(prefix + 'blue', colour.blue);
    }
    if (colour.colourGroup) {
        formData.append(prefix + 'colourGroup.id', colour.colourGroup.id);
    }
    return formData;
}

export function encodeJobItemTemplateBasic(formData, jobItemTemplate, prefix = '') {
    if (jobItemTemplate.id) {
        formData.append(prefix + 'id', jobItemTemplate.id);
    }
    formData.append(prefix + 'name', jobItemTemplate.name);
    formData.append(prefix + 'uom', jobItemTemplate.uom);
    formData.append(prefix + 'calculation', jobItemTemplate.calculation);
    let i = 0;
    jobItemTemplate.jobTaskTemplates.forEach(item => {
        formData.append(prefix + `jobTaskTemplates[${i}].type`, item.type);
        formData.append(prefix + `jobTaskTemplates[${i}].mandatory`, item.mandatory);
        i++;
    });
    return formData;
}