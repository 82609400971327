<template>
    <div>
        <div>
            <strong class="panel-section-header mt-0">
                Details
            </strong>
            <v-table density="compact" class="text-caption overflow-hidden">
                <tbody>
                    <tr align="right">
                        <th>
                            Email
                        </th>
                        <td>
                            <new-email-btn :address="entityRecord.accountsEmail"
                                           :name="entityRecord.name"
                                           :contact="entityRecord"></new-email-btn>
                        </td>
                    </tr>
                    <tr align="right">
                        <th>Phone</th>
                        <td>{{ entityRecord.phone }}</td>
                    </tr>
                    <tr v-if="entityRecord.defaultNotifierEmail" align="right">
                        <th>Default Job Notifiers</th>
                        <td>
                            <div v-for="email in entityRecord.defaultNotifierEmail.split(',')" :key="email">{{ email }}</div>
                        </td>
                    </tr>
                    <tr align="right">
                        <th>Group</th>
                        <td><span v-if="entityRecord.contactGroup">{{ entityRecord.contactGroup.name }}</span></td>
                    </tr>
                    <tr v-if="entityRecord.importFileImportType">
                        <td colspan="2">
                            <contact-job-file-import :contact="entityRecord"></contact-job-file-import>
                        </td>
                    </tr>
                </tbody>
            </v-table>
            <v-btn size="small" class="border-radius-0" prepend-icon="mdi-content-copy" block @click="copyText(buildPublicContactLink(entityRecord.publicCode), 'Link')">
                Copy Customer Link
            </v-btn>
        </div>
        <div>
            <strong class="panel-section-header mt-2">
                Contacts
            </strong>
            <v-card v-for="person in entityRecord.contactPeople" :key="person.id" style="border-radius:0px!important">
                <v-card-title>
                    {{ person.name }}
                </v-card-title>
                <v-card-subtitle v-if="person.role">
                    {{ person.role }}
                    <v-chip v-if="person.decisionRole" variant="flat"
                            :color="person.decisionRole === 'Key Decision Maker' ? 'deep-orange-lighten-1' : 'blue-grey-lighten-2'" size="x-small">
                        <v-icon start icon="mdi-key"></v-icon>
                        {{ person.decisionRole }}
                    </v-chip>
                </v-card-subtitle>
                <v-card-text>
                    <a v-if="person.phone" class="mr-1 mt-1" color="blue-darken-4" :href="'tel:'+person.phone">{{ person.phone }}</a>
                    <new-email-btn :address="person.email"
                                   :name="person.name"
                                   :contact="entityRecord"
                                   :contact-person="person"
                                   class="mb-1"
                                   v-if="person.email"></new-email-btn>
                    <div v-if="person.hasLogin == false" class="mb-2">
                        <v-btn size="x-small" :loading="person.loading" @click="requestRegisterContact(person)"><v-icon icon="mdi-login-variant" class="mr-1"></v-icon>REGISTER LOGIN</v-btn>
                    </div>
                    <div v-if="person.hasLogin == true" class="mb-2">
                        <v-row>
                            <v-col>
                                <b><v-icon icon="mdi-login-variant" class="mr-1"></v-icon> HAS LOGIN</b>
                            </v-col>
                            <v-col>
                                <v-btn size="x-small" prepend-icon="mdi-content-copy" @click="copyText(person.loginPassword, 'Password')">
                                    Copy Password
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </div>
        <v-dialog v-model="showConfirmRegister"
                  width="auto">
            <v-card>
                <v-card-text>
                    Are you sure you want register this person for a login?
                </v-card-text>
                <v-card-actions>
                    <v-btn v-if="registerPerson"
                           :loading="registerPerson.loading"
                           color="primary"
                           @click="confirmRegister"
                           dark>
                        REGISTER
                    </v-btn>
                    <v-btn v-if="registerPerson" dark @click="showConfirmRegister = false" :disabled="registerPerson.loading">
                        CANCEL
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
    .key-contact-person {
        border-color: #ff7043!important;
        border-width:2px;
    }
    .influencer-contact-person {
        border-color: #90a4ae !important;
        border-width: 2px;
    }
</style>
<script>
    import { mapActions, mapState } from "vuex";
    import { buildPublicContactLink } from "../../mixins/applicationMixins";
    function initialState() {
        return {
            saving: false,
            loading: false,
            registerPerson: null,
            showConfirmRegister: false
        };
    }
    export default {
        name: "ContactDetailsPanel",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        components: {
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
                "reloadEntities"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            buildPublicContactLink,
            copyText(link, type) {
                // Select the text field
                navigator.clipboard.writeText(link);
                this.showSuccessSnack(type+" copied!");
            },
            requestRegisterContact(person) {
                this.registerPerson = person;
                this.showConfirmRegister = true;
            },
            confirmRegister() {
                this.registerPerson.loading = true;
                this.$api.post('/auth/registercontactperson', this.registerPerson.id)
                    .then(res => {
                        this.registerPerson = null;
                        this.showSuccessSnack("Person registered");
                        this.reloadEntities();
                        this.showConfirmRegister = false;
                    }).catch(error => {
                        this.registerPerson.loading = false;
                        this.showErrorSnack("Sorry we couldnt register this person.");
                    });
            }
        },
        computed: {
            ...mapState({
                entityRecord: (state) => state.entities.entityRecord,
                entityRecordLoading: (state) => state.entities.entityRecordLoading
            }),
        }
    };
</script>