<template>
    <v-layout>
        <v-navigation-drawer theme="dark" expand-on-hover rail absolute permanent id="main-nav">
            <v-list>
                <v-list-item v-if="user" :title="user.name"
                             :subtitle="user.email">
                    <template v-slot:prepend>
                        <avatar-small :object="user"></avatar-small>
                    </template>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list>
                <v-list-item v-for="item in menuItems"
                             :key="item.route"
                             :prepend-icon="item.icon"
                             variant="plain"
                             rounded="xl"
                             :to="item.route"
                             :title="item.title">
                </v-list-item>
                <v-list-item prepend-icon="mdi-logout"
                             variant="plain"
                             rounded="xl"
                             selectable
                             @click="logout"
                             title="Log out">
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar color="black">
            <v-avatar :tile="true" class="ml-4">
                <v-img src="/assets/logosmall.png"></v-img>
            </v-avatar>
            <template v-slot:append>
                <v-tooltip text="Budget Schedule" location="bottom">
                    <template v-slot:activator="{ props }">
                        <v-btn variant="plain" icon="mdi-bullseye-arrow" v-bind="props" class="mr-2" :class="{'expanded-btn' : showCalendar}" @click="toggleViewCalendar">
                        </v-btn>
                    </template>
                </v-tooltip>
                <search-bar></search-bar>
                <notifications-bar></notifications-bar>
            </template>
        </v-app-bar>
        <v-navigation-drawer permanent v-if="showSettingsMenu">
            <v-list rounded="lg">
                <v-list-item v-for="item in settingsMenuItems"
                             :key="item.route"
                             :prepend-icon="item.icon"
                             variant="plain"
                             :to="item.route"
                             :title="item.title">
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-main class="w-100">
            <div class="w-100 main-view">
                <div class="target-calendar" :class="{'expanded':showCalendar}">
                    <target-calendar :open-view="showCalendar"></target-calendar>
                </div>
                <div class="pa-1">
                    <breadcrumb-bar></breadcrumb-bar>
                    <router-view />
                    <new-email-dialog></new-email-dialog>
                    <reschedule-job-dialog></reschedule-job-dialog>
                    <warehouse-add-dialog></warehouse-add-dialog>
                    <smart-job-rework-dialog></smart-job-rework-dialog>
                </div>
            </div>
            <history-footer v-if="showHistory"></history-footer>
        </v-main>
        <v-navigation-drawer :permanent="mdAndUp" v-if="showNotes" location="right" :expand-on-hover="!mdAndUp">
            <note-bar></note-bar>
        </v-navigation-drawer>
    </v-layout>
</template>
<style scoped>
    .v-input.expanding-search {
        transition: max-width 0.5s
    }
    .v-input__slot,.closed {
        max-width: 70px;
    }
    .main-view {
        min-height:700px;
    }
    .target-calendar {
        display: flex;
        background-color: white;
        transition: all 0.3s ease;
        height: 0px;
        border-bottom: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
        overflow: hidden;
        background: linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255, 255, 255, 0.4) 0%, rgba(0, 0, 0, 0.25) 200%);
        color:white;
    }
    .target-calendar.expanded{
        height:200px;
    }
    .close-btn {
        position:fixed;
        right:0px;
    }
    .expanded-btn {
        color: #1565C0 !important;
    }
</style>
<script setup>
    import { useDisplay } from 'vuetify'

    const { mdAndUp } = useDisplay()
</script>
<script>
    import { mapActions, mapState, mapGetters } from "vuex";
    function initialState() {
        return {
            menuItems: [],
            settingsMenuItems: [],
            showCalendar: false
        };
    }
    export default {
        name: "AppLayout",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.menuItems = [
                {
                    icon: 'mdi-view-dashboard',
                    title: 'Dashboard',
                    route: '/'
                }
            ];
            if (this.hasSupportAccess) {
                this.menuItems.push({
                    icon: this.$constants.entities.Job.ICON,
                    title: this.$constants.entities.Job.PLURAL,
                    route: '/jobs'
                });
                this.menuItems.push({
                    icon: this.$constants.entities.Contact.ICON,
                    title: this.$constants.entities.Contact.PLURAL,
                    route: '/contacts'
                });
                this.menuItems.push({
                    icon: this.$constants.entities.Product.ICON,
                    title: this.$constants.entities.Product.PLURAL,
                    route: '/products'
                });
                this.menuItems.push({
                    icon: this.$constants.entities.Colour.ICON,
                    title: this.$constants.entities.Colour.PLURAL,
                    route: '/colours'
                });
                this.menuItems.push({
                    icon: this.$constants.entities.Staff.ICON,
                    title: this.$constants.entities.Staff.PLURAL,
                    route: '/staff'
                });
            }
            this.menuItems.push({
                icon: 'mdi-file-chart',
                title: 'Reports',
                route: '/reports'
            });
            if (this.hasAdminAccess){

                this.menuItems.push({
                    icon: 'mdi-cog',
                    title: 'Settings',
                    route: '/settings'
                });
                this.settingsMenuItems = [
                    {
                        icon: 'mdi-web',
                        title: 'General',
                        route: '/settings/'
                    },
                    {
                        icon: this.$constants.entities.ApplicationUser.ICON,
                        title: this.$constants.entities.ApplicationUser.PLURAL,
                        route: '/settings/users'
                    },
                    {
                        icon: this.$constants.entities.ColourGroup.ICON,
                        title: this.$constants.entities.ColourGroup.PLURAL,
                        route: '/settings/colourgroups'
                    },
                    {
                        icon: this.$constants.entities.ContactGroup.ICON,
                        title: this.$constants.entities.ContactGroup.PLURAL,
                        route: '/settings/contactgroups'
                    },
                    {
                        icon: this.$constants.entities.JobItemTemplate.ICON,
                        title: this.$constants.entities.JobItemTemplate.PLURAL,
                        route: '/settings/jobitemtemplates'
                    },
                    {
                        icon: this.$constants.entities.MessageTemplate.ICON,
                        title: this.$constants.entities.MessageTemplate.PLURAL,
                        route: '/settings/messagetemplates'
                    },
                    {
                        icon: 'mdi-connection',
                        title: 'Xero',
                        route: '/settings/xero'
                    },
                    {
                        icon: 'mdi-connection',
                        title: 'Smartsheet',
                        route: '/settings/smartsheet'
                    }
                ];
            }
        },
        props: {
        },
        methods: {
            ...mapActions([
                "logout",
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            toggleViewCalendar() {
                this.showCalendar = !this.showCalendar;
            }
        },
        computed: {
            ...mapState({
                user: (state) => state.auth.user,
                entity: (state) => state.entities.entity
            }),
            ...mapGetters([
                'hasAdminAccess',
                'hasSupportAccess'
            ]),
            showSettingsMenu() {
                return this.hasAdminAccess && this.$route.name.startsWith('settings');
            },
            showNotes() {
                return this.$route.meta.showNotes === true;
            },
            showHistory() {
                return this.$route.meta.showHistory === true;
            }
        }
    };
</script>