<template>
    <div class="mt-2">
        <v-overlay :model-value="loading"
                   class="align-center justify-center">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="64"></v-progress-circular>
        </v-overlay>
        <v-row class="mb-2">
        </v-row>
    </div>
</template>
<script>
    import { mapActions } from "vuex";
    import { numberWithCommas } from "../../mixins/numberMixins";
    import { formatSearchName } from "../../mixins/searchMixins";
    function initialState() {
        return {
            loading: false,
            today: null,
            data: [],
            searchTerm: '',
        };
    }
    export default {
        name: "ReworkDashboard",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loadData();
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
                "updateSmartJobStatus"
            ]),
            formatSearchName,
            numberWithCommas,
            loadData() {
            },
        },
        computed: {
        },
        watch: {
        }
    };
</script>
<style scoped>
</style>